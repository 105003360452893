
import React from "react";
import { useState, useEffect } from "react";
import { GiHamburgerMenu } from 'react-icons/gi';
import { BiSearchAlt, BiHelpCircle } from 'react-icons/bi';
import { AiFillFire, AiFillWallet } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { FaShippingFast, FaUserFriends } from 'react-icons/fa';
import { BsFillHeartFill, BsTagFill, BsInstagram } from 'react-icons/bs';
import { FaShoppingCart } from "react-icons/fa";

export default function NavbarOptions() {
    const [mobileOption, setMobileOption] = useState(false);
    const iconColor = 'text-orange-200 mr-2';
    return (
        <div className="">
            {/* here lg:absolute was there but changed to fixed */}
            <div className="lg:fixed lg:navbar md:absolute md:navbar hidden z-10">
                <div className="flex-1 gap-0 ">
                    {/* hamburger menu starts */}
                    <div className="ml-28 z-0">
                        <div className="flex items-center">
                            <div className="lg:block md:block hidden drawer lg:mr-10 md:mr-10 mr-3">
                                <div className="drawer-content ">
                                    <label className="drawer-button cursor-pointer" onClick={() => setMobileOption(!mobileOption)}>
                                        <GiHamburgerMenu className="lg:block md:block hidden" size={30} />
                                        <GiHamburgerMenu className="lg:hidden md:hidden" size={20} />
                                    </label>
                                </div>
                                <div className="" >
                                    {mobileOption ? <label htmlFor="my-drawer" className="fixed w-full h-screen z-10 top-0 left-0" onClick={() => setMobileOption(!mobileOption)}></label>
                                        : ''
                                    }
                                    <div id="sidebar" className={mobileOption ? "menu fixed w-[300px] h-screen z-10 top-0 left-0 bg-base-100 duration-500" : "menu fixed w-[300px] h-screen z-10 top-0 left-[-100%] bg-base-100 duration-500"}>
                                        <div className="text-blue-200 absolute top-0 right-0 p-2 cursor-pointer hover:bg-red-300 rounded-full" onClick={() => setMobileOption(!mobileOption)}>
                                            <IoMdClose size={30} />
                                        </div>
                                        <ul className="text-black font-medium text-2xl mt-16 ml-2">
                                            <li className={`hover:bg-cyan-500 rounded-full`}>
                                                <a className="hover:text-black" >
                                                    <FaShippingFast className={iconColor} />
                                                    Orders
                                                </a>
                                            </li>
                                            <li className="hover:bg-cyan-500 rounded-full">
                                                <a className="hover:text-black" >
                                                    <BsFillHeartFill className={iconColor} />
                                                    Favourite
                                                </a>
                                            </li>
                                            <li className="hover:bg-cyan-500 rounded-full">
                                                <a className="hover:text-black" >
                                                    <AiFillWallet className={iconColor} />
                                                    Wallet
                                                </a>
                                            </li>
                                            <li className="hover:bg-cyan-500 rounded-full">
                                                <a className="hover:text-black" >
                                                    <BiHelpCircle className={iconColor} />
                                                    Help
                                                </a>
                                            </li>
                                            <li className="hover:bg-cyan-500 rounded-full">
                                                <a className="hover:text-black" >
                                                    <BsTagFill className={iconColor} />
                                                    Promotion
                                                </a>
                                            </li>
                                            <li className="hover:bg-cyan-500 rounded-full">
                                                <a className="hover:text-black" >
                                                    <AiFillFire className={iconColor} />
                                                    Best One
                                                </a>
                                            </li>
                                            <li className="hover:bg-cyan-500 rounded-full">
                                                <a className="hover:text-black" >
                                                    <FaUserFriends className={iconColor} />
                                                    Invite Friend
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* : ""} */}
                            </div>

                            {/* <h1 className="text-lg text-3xl lg:text-4xl lg:px-2 pl-8 cursor-pointer">
                        Shopping <span className="font-bold"> Heritage </span>
                    </h1> */}
                        </div>
                    </div>

                    {/* hamburger menu ends */}
                    {/* company logo starts */}
                    <div className="w-20 ml-14 link" href="/">
                        <img className="rounded-full p-0" src="https://i.imgur.com/xHMOLh0.jpg" alt=""/>
                    </div>
                    {/* company logo ends */}

                    {/* search button starts */}
                    <div className="ml-32 bg-gray-200 rounded-full hidden md:flex lg:flex itmes-center p-2 w-[200px] sm:w-[400px] lg:w-[500px]">
                        <BiSearchAlt size={25} />
                        <input type="text" placeholder="search item" className="bg-transparent w-full px-2 focus:outline-none" />
                    </div>
                    {/* search button ends */}

                </div>

                <div className="flex-none gap-10">
                    {/* cart start */}
                    <div className="lg:flex-none ">
                        <div className="dropdown dropdown-end">
                            <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
                                <div className="indicator">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" /></svg>
                                    <span className="badge badge-sm indicator-item">8</span>
                                </div>
                            </div>
                            <div tabIndex={0} className="mt-3 z-[1] card card-compact dropdown-content w-52 bg-base-100 shadow">
                                <div className="card-body">
                                    <span className="font-bold text-lg">8 Items</span>
                                    <span className="text-info">Subtotal: $999</span>
                                    <div className="card-actions">
                                        <button className="btn btn-primary btn-block">View cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* cart end */}

                    {/* profile starts */}
                    <div className="lg:dropdown dropdown-end md:hidden sm:hidden">
                        <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                            <div className="w-10 rounded-full">
                                <img alt="Tailwind CSS Navbar component" src="https://images.unsplash.com/photo-1583083527882-4bee9aba2eea?q=80&w=1977&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                            </div>
                        </div>
                        <ul tabIndex={0} className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52">
                            <li>
                                <a className="justify-between">
                                    Profile
                                    <span className="badge">New</span>
                                </a>
                            </li>
                            <li><a>Settings</a></li>
                            <li><a>Logout</a></li>
                        </ul>
                    </div>
                    {/* profile ends */}
                </div>
            </div>

            {/* for mobile */}
            {/* Search Input for mobile */}
            <div className="lg:hidden z-10 absolute">
                <div className="flex items-center ml-5 mt-4">
                    <div className="lg:hidden md:block drawer lg:mr-10 md:mr-10 mx-7">
                        <div className="drawer-content ">
                            <label className="drawer-button cursor-pointer" onClick={() => setMobileOption(!mobileOption)}>
                                <GiHamburgerMenu className="lg:hidden md:hidden" size={20} />
                            </label>
                        </div>
                        <div className="" >
                            {mobileOption ? <label htmlFor="my-drawer" className="fixed w-full h-screen z-10 top-0 left-0" onClick={() => setMobileOption(!mobileOption)}></label>
                                : ''
                            }
                            <div id="sidebar" className={mobileOption ? "menu fixed w-[300px] h-screen z-10 top-0 left-0 bg-base-100 duration-500" : "menu fixed w-[300px] h-screen z-10 top-0 left-[-100%] bg-base-100 duration-500"}>
                                <div className="text-blue-200 absolute top-0 right-0 p-2 cursor-pointer hover:bg-red-300 rounded-full" onClick={() => setMobileOption(!mobileOption)}>
                                    <IoMdClose size={30} />
                                </div>
                                <ul className="text-black font-medium text-2xl mt-16 ml-2">
                                    <li className={`hover:bg-cyan-500 rounded-full`}>
                                        <a className="hover:text-black" >
                                            <FaShippingFast className={iconColor} />
                                            Orders
                                        </a>
                                    </li>
                                    <li className="hover:bg-cyan-500 rounded-full">
                                        <a className="hover:text-black" >
                                            <BsFillHeartFill className={iconColor} />
                                            Favourite
                                        </a>
                                    </li>
                                    <li className="hover:bg-cyan-500 rounded-full">
                                        <a className="hover:text-black" >
                                            <AiFillWallet className={iconColor} />
                                            Wallet
                                        </a>
                                    </li>
                                    <li className="hover:bg-cyan-500 rounded-full">
                                        <a className="hover:text-black" >
                                            <BiHelpCircle className={iconColor} />
                                            Help
                                        </a>
                                    </li>
                                    <li className="hover:bg-cyan-500 rounded-full">
                                        <a className="hover:text-black" >
                                            <BsTagFill className={iconColor} />
                                            Promotion
                                        </a>
                                    </li>
                                    <li className="hover:bg-cyan-500 rounded-full">
                                        <a className="hover:text-black" >
                                            <AiFillFire className={iconColor} />
                                            Best One
                                        </a>
                                    </li>
                                    <li className="hover:bg-cyan-500 rounded-full">
                                        <a className="hover:text-black" >
                                            <FaUserFriends className={iconColor} />
                                            Invite Friend
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* : ""} */}
                    </div>
                    <div className="lg:hidden md:hidden bg-gray-200 rounded-lg flex itmes-center p-1 w-2/3 bg-pink-100">
                        <BiSearchAlt size={20} className='my-0.5' />
                        <input type="text" placeholder="search item" className="bg-transparent w-full px-2 focus:outline-none" />
                    </div>
                    <div className="text-cyan-700 ml-7 rounded mr-4">
                        <FaShoppingCart size={20} />
                    </div>
                </div>
            </div>
        </div>
    );
}