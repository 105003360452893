import React, { useRef, useState, useEffect,useLayoutEffect } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import NavbarOptions from './NavbarOptions';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import "./styles.css";

export default function Navbar() {
    // 
    let timer = null;
    let elems = useRef([]);
    let timeline = gsap.timeline({
        defaults: {
            duration: 0.75,
            ease: "power3.inOut"
        },
        paused: true
    });


    const [state, setState] = useState({ current: 0, next: 1 });
    const [userDetected, setUserDetected] = useState(false);

    const activateTimer = () => {
        timer = setTimeout(() => {
            stepForward();
        }, 4000);
    };

    const calculateIndexs = (index) => {
        if (index === gallery.length - 1) {
            setState({ current: index, next: 0 });
        } else {
            setState({ current: index, next: index + 1 });
        }
    };

    const flowUp = (onComplete) => {
        timeline
            .to(elems.current[0], { y: "-100%", opacity: 0, scale: -0.5 })
            .to(
                elems.current[1],
                {
                    y: "-100%",
                    opacity: 1,
                    scale: 1,
                    onComplete
                },
                "-=0.75"
            )
            .play();
    };

    const fadeOut = (onComplete) => {
        timeline
            .to(elems.current[0], {
                duration: 0.5,
                opacity: 0,
                onComplete
            })
            .to(elems.current[0], { opacity: 1 })
            .play();
    };

    const handleChange = (index) => {
        if (index !== state.current) {
            clearTimeout(timer);
            setUserDetected(true);
            fadeOut(() => calculateIndexs(index));
        }
    };

    const stepForward = () => {
        setUserDetected(false);
        flowUp(() => calculateIndexs(state.next));
    };

    useLayoutEffect(() => {
        const image1 = !!elems.current[0] && elems.current[0];
        const image2 = !!elems.current[1] && elems.current[1];

        activateTimer();

        gsap.set(image2, { y: "0%", opacity: 0, scale: 1 });
        if (userDetected) {
            gsap.set(image1, { y: "0%", opacity: 0, scale: 1 });
        } else {
            gsap.set(image1, { y: "0%", opacity: 1, scale: 1 });
        }

        return () => {
            clearTimeout(timer);
        };
    }, [state]);

    // 
    const budhastatue = "https://i.imgur.com/Tfhwlqc.png", rudrakhs = "https://i.imgur.com/jwAzeS9.png";
    const imageLinks = [budhastatue, rudrakhs];
    const gallery = [
        {
            title: "Budha Statue",
            cover:
                "https://i.imgur.com/Tfhwlqc.png"
        },
        {
            title: "Rudrakhs",
            cover:
                rudrakhs
        },
        {
            title: "title three",
            cover:
                "https://images.unsplash.com/photo-1617643606475-99ad26026885?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1268&q=80"
        },
        {
            title: "title four",
            cover:
                "https://icons8.com/wp-content/uploads/2020/02/digital-illustration-brian-edward-miller.jpg"
        }
    ];

    const navRef = useRef(null);
    const delay = 25;
    const [index, setIndex] = useState(0);

    useEffect(() => {
        gsap.from(navRef.current, { duration: 2, autoAlpha: 0, ease: 'none' })
    }, [navRef])



    return (

        <div className='lg:h-screen'>
            <div className=''>
                <NavbarOptions />
            </div>

            <div className="album-container">
                <div className="image">
                    <img
                        ref={(elem) => (elems.current[0] = elem)}
                        src={gallery[state.current].cover}
                        alt=""
                    />
                    {/* <p>{gallery[state.current].title}</p> */}
                </div>
                {/* <div className="image">
                    <img
                        ref={(elem) => (elems.current[1] = elem)}
                        src={gallery[state.next].cover}
                        alt=""
                    />
                    <p>{gallery[state.next].title}</p>
                </div> */}
                <div className="stripes">
                    {gallery.map((_item, index) =>
                        index === state.current ? (
                            <span
                                key={`stripe${index}`}
                                onClick={() => handleChange(index)}
                                style={{ opacity: 1 }}
                            ></span>
                        ) : (
                            <span
                                key={`stripe${index}`}
                                onClick={() => handleChange(index)}
                                style={{ opacity: 0.5 }}
                            ></span>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}
