// import Navbar from './components/Navbar.jsx';
// import Footer from './components/Footer.jsx';
import Automoving from './components/Automoving.jsx';
import Navbar from './components/nav/Navbar.jsx';
import Footer from './components/footer/Footer.jsx';
import About from './components/About-home/About.jsx';


import {
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
  useLocation
} from "react-router-dom";


const code = new URLSearchParams(window.location.search).get('code')
function App() {
  return (
    <>
      <Router>
        <Navbar />
        {/* <Automoving /> */}
        {/* <SlideShow/> */}
        {/* <Swiper /> */}
        <About/>
        <Footer />
        <Routes>
          {/* <Route path='/' element={<Homepage />} />
          <Route path='/about' element={<About />} /> */}
        </Routes>
      </Router>

    </>
  );
}

export default App;
