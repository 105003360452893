import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/all';
// import { SplitText } from 'gsap-trial/all';
import "./style.css"
gsap.registerPlugin(ScrollTrigger);
// gsap.registerPlugin(SplitText);
// const split = new SplitText('p', { type: "lines" });
// const split = ""

export default function About() {
    const textValue = `Step into a world where tradition and spirituality converge seamlessly. 
    Our e-commerce destination offers a handpicked selection of traditional 
    handmade items, meditation essentials, gemstones, and 
    sacred rudraksha beads. Each piece encapsulates timeless artistry, 
    inviting you to adorn your life with authenticity and 
    embark on a journey of self-discovery. 
    Welcome to a space where every purchase is a mindful step 
    towards a more connected and meaningful existence.`;
    const scrolls = useRef(null);

    const [lettersRef, setlettersRef] = useArrayRef();
    const triggerRef = useRef(null);

    function useArrayRef() {
        const lettersRef = useRef([]);
        lettersRef.current = [];
        return [lettersRef, (ref) => ref && lettersRef.current.push(ref)];
    }

    useEffect(() => {
        console.log(lettersRef.current)
        const anim = gsap.to(
            lettersRef.current,
            {
                scrollTrigger: {
                    trigger: triggerRef.current,
                    scrub: true,
                    start: "top center",
                    end: "bottom 90%"

                },
                // color: "#00FF00",
                // backgroundColor: "#2A2A2A",
                // duration: 1,
                // stagger: 1,

                opacity: 1,
                ease: "none",
                duration: 3,
                stagger: 1
            }
        );
        // return (() => {
        //     anim.kill()
        // })
    }, []);

    return (
        <div className='container m-0 p-0 h-[auto] overflow-hidden '>

            <div className='text h-[100%] bg-red-200 flex justify-center'>

                <div className='grid content-center'>
                    <div className='flex justify-center'>
                        <span className='pt-9 pl-0 lg:font-bold lg:text-8xl text-6xl text-red-900'>
                            Krinnati
                        </span>
                    </div>
                    <div ref={triggerRef} className='p-14 pt-4 grid content-center'>
                        {/* {textValue.split("\n").map((letter, index) => (
                        <span ref={setlettersRef} key={index} className='p-20 font-bold text-4xl tracking-wider'>
                            {letter}
                        </span>
                    ))} */}
                        {textValue.split("\n").map((letter, index) => (
                            <span ref={setlettersRef} key={index} className='sm:font-bold sm:text-lg lg:font-bold lg:text-2xl tracking-wider opacity-0'>
                                {letter}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
